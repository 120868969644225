<template>
  <Containers class="w-full" :status="status" @funEnd='funEnd' :active='3' >

    <div class="w">
      <!-- banner开始 -->
      <div class="w">
        <banner class="w-full" title="NEWS" ></banner>
      </div>
      <!-- banner结束 -->

      <!-- 招牌产品开始 -->
      <div class="news w p-0 m-0" >
        <headlines class="headlines font-XQ" title='新闻列表' english="NEWS LIST" color="#1E456C" ></headlines>
        <a-row class="news-container pc-show" :gutter="[80,80]" type="flex">
          <a-col :sm="24" :md="12" :lg="8" class="news-container-item text-blue m-0 cursor-pointer grid gap-y-10 " v-for="(item,index) in news" :key="index" @click="toDetail(item.objectId)">
            <div class="news-container-item-img" :style="{backgroundImage: 'url(' + item.picture + ')','background-repeat': 'no-repeat','backgroundSize': 'cover','background-position': 'center center'}"></div>
            <div class="ell_2">{{item.name}}</div>
            <div class="text-14">{{item.createdAt | YMD }}</div>
          </a-col>
        </a-row>
        <div class="news-container-mb mb-show">
          <div class="news-container-item-mb flex text-blue" v-for="(item,index) in news" :key="index" @click="toDetail(item.objectId)">
            <div class="left-img flex-shrink-0" :style="{backgroundImage: 'url(' + item.picture + ')','background-repeat': 'no-repeat','backgroundSize': 'cover','background-position': 'center center'}"></div>
            <div class="right">
              <div class="ell_2">{{item.name}}</div>
              <div class="text-12">{{item.createdAt | YMD }}</div>
            </div>
          </div>
        </div>
        <pagination
          v-if="newsNum !== 0"
          class="pagination w-full"
          :show-jumper="true"
          :total="newsNum"
          :currentPage="page"
          :pagerCount="5"
          @current-change="currentChange"
        />
      </div>
      <!-- 招牌产品结束 -->

    </div>
  </Containers>
</template>

<script>
import { WOW } from 'wowjs'

import headlines from '@/components/headlines'
import banner from '@/components/banner'
import pagination from '@/components/pagination'

export default {
  metaInfo: {
    title: '新闻资讯_双美豆沙牛乳官方网站',
    meta: [
      {
        name: 'keywords',
        content: '双美豆沙牛乳新闻资讯_双美豆沙牛乳官网'
      },
      {
        name: 'description',
        content: '本页面为您提供双美豆沙牛乳新闻资讯，包含最新新品信息、最新店面信息、开店技巧、运营技巧、联名活动等双美豆沙牛乳品牌各方面信息'
      }
    ]
  },
  data () {
    return {
      status: 'success',
      page: 1,
      newsNum: 0,
      news: []
    }
  },
  mounted () {
    this.getInit()
  },
  components: {
    headlines,
    banner,
    pagination
  },
  computed: {
  },
  watch: {
    page () {
      this.getNews()
    }
  },
  methods: {
    funEnd () {
      setTimeout(() => {
        // this.status = 'success'
        // this.$nextTick(() => {
        //   // 在dom渲染完后,再执行动画
        //   const wow = new WOW({
        //     live: false
        //   })
        //   wow.init()
        // })
      }, 300)
    },
    async getInit () {
      try {
        await this.getNews()
        setTimeout(() => {
          this.$nextTick(() => {
            // 在dom渲染完后,再执行动画
            const wow = new WOW({
              live: false
            })
            wow.init()
          })
        }, 0)
      } catch (e) {
        this.status = 'error'
      }
    },
    async getNews () {
      let list = await this.$axios.get('/classes/newsList', {
        params: {
          count: 1,
          order: '-createdAt',
          limit: 12,
          skip: (this.page - 1) * 12,
        }
      })
      this.news = list.results
      this.newsNum = Math.ceil(list.count / 12)
    },
    currentChange (page, old) {
      this.page = page
    },
    toDetail (id) {
      this.$push({
        path: '/news-detail',
        query: {
          id: id
        }
      })
    }

  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/mixin1700.scss';

.news{
  padding: 4vw 8vw 7vw !important;
  background-color: #FCFAF0;
  @include hamburger {
    padding: 30px 12px !important;
  }
  .headlines{
    margin-bottom: 80px;
    @include hamburger {
      margin-bottom: 10px;
    }
  }
  .news-container{
    // padding: 80px 0;
  }
  .news-container-item{
    &:hover{
      color: #CA4938 !important;
    }
    .news-container-item-img{
      width: 100%;
      height: 230px;
    }
  }
  .pagination{
    margin-top: 80px;
    @include hamburger {
      margin-top: 10px;
      ::v-deep{
        justify-content: end;
      }
    }
  }
}

.news-container-mb{
  // padding: 80px 0;
  .news-container-item-mb{
    margin-bottom: 18px;
    .left-img{
      width: 120px;
      height: 70px;
      margin-right: 8px;
    }
  }
}

</style>
